import React from "react"
import { motion } from "framer-motion"

import "./checkbox.css"

const Checkbox = ({ onToggle, label, animate }) => {
  const variants = {
    initial: {
      backgroundColor: "#fff",
      border: "1px solid black",
    },
    checked: {
      backgroundColor: "#000",
    },
  }

  return (
    <>
      <motion.div
        variants={variants}
        id={"checkbox-container"}
        onClick={onToggle}
        initial={"initial"}
        animate={animate}
      >
        <motion.p className={"paragraph uppercase"}>{label}</motion.p>
      </motion.div>
    </>
  )
}

export default Checkbox
