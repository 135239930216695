import React, { useState, useContext } from "react"
import { motion } from "framer-motion"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

import Input from "../Input/Input"

import "./country-selector.css"

const CountrySelector = ({ onCountryChange }) => {
  const [open, setOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(false)
  const [country, setCountry] = useState("")

  const state = useContext(GlobalStateContext)

  const variants = {
    open: { height: "auto" },
    closed: { height: "40px" },
    hover: { backgroundColor: state.theme.accent },
  }

  const selectionVariants = {
    initial: { backgroundColor: "#fff" },
    intialBorder: { border: "1px solid #000" },
    hover: { backgroundColor: state.theme.accent },
    error: { border: "1px solid #FF5689" },
  }

  const transition = {
    type: "tween",
    delay: 0,
    duration: 0.2,
    ease: [0, 0, 1, 1],
  }

  React.useEffect(() => {
    onCountryChange(country)
  }, [country, onCountryChange])

  return (
    <>
      <motion.div
        id={"country-selector-container"}
        variants={variants}
        initial={"closed"}
        animate={open ? "open" : "closed"}
        transition={transition}
      >
        <motion.div
          id={"country-selector-button"}
          variants={selectionVariants}
          initial={"intialBorder"}
          whileHover={"hover"}
          animate={!selectedCountry ? "error" : "intialBorder"}
          onClick={() => setOpen(!open)}
        >
          <h3 className={"country-selector-text"}>
            {!selectedCountry ? (
              <span style={{ color: "#8e8e8e" }}>* COUNTRY</span>
            ) : (
              selectedCountry
            )}
          </h3>
        </motion.div>
        <motion.div
          id={"country-netherlands"}
          variants={selectionVariants}
          initial={"initial"}
          whileHover={"hover"}
          onClick={() => {
            setSelectedCountry("NETHERLANDS")
            setCountry("Netherlands")
            setOpen(!open)
          }}
        >
          <h3 className={"country-selector-text"}>
            NETHERLANDS (€10 SHIPPING)
          </h3>
        </motion.div>
        <motion.div
          id={"country-international"}
          variants={selectionVariants}
          initial={"initial"}
          whileHover={"hover"}
          onClick={() => {
            setSelectedCountry("INTERNATIONAL")
            setCountry("")
            setOpen(!open)
          }}
        >
          <h3 className={"country-selector-text"}>
            INTERNATIONAL (€15 SHIPPING)
          </h3>
        </motion.div>
      </motion.div>
      {selectedCountry === "INTERNATIONAL" ? (
        <Input
          onChange={e => {
            setCountry(e.target.value)
          }}
          placeholder={"* COUNTRY"}
          animate={country === "" ? "error" : "initial"}
        />
      ) : null}
    </>
  )
}

export default CountrySelector
