import React from "react"
import { motion } from "framer-motion"

import "./input.css"

const Input = ({ onChange, placeholder, animate }) => {
  const variants = {
    initial: { border: "1px solid #000" },
    error: { border: "1px solid #FF5689" },
  }

  return (
    <motion.input
      className={"input"}
      onChange={onChange}
      placeholder={placeholder}
      variants={variants}
      initial={"initial"}
      animate={animate}
    />
  )
}

export default Input
