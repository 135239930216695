import React, { useContext, useState, useEffect } from "react"
import { motion } from "framer-motion"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import DesktopHeader from "../components/DesktopHeader/DesktopHeader"
import Button from "../components/Button/Button"
import Input from "../components/Input/Input"
import Checkbox from "../components/Checkbox/Checkbox"
import CountrySelector from "../components/CountrySelector/CountrySelector"

import { useWindowSize } from "../utils/useWindowSize"
import { getSalePrice } from "../utils/getSalePrice"
import { getDiscountPrice } from "../utils/getDiscountPrice"
import { constants } from "../utils/constants"

import { GlobalStateContext } from "../context/GlobalContextProvider"

const CheckoutPage = () => {
  const state = useContext(GlobalStateContext)

  const { width } = useWindowSize()

  // State to hold local pickup
  const [localPickup, setLocalPickup] = React.useState(false)

  // State for filled in shipping info
  const [allFormsFilled, setAllFormsFilled] = useState(false)

  // State to hold processing
  const [processing, setProcessing] = useState(false)

  // State to hold customer information
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  // State to hold shipping information
  const [address, setAddress] = useState("")
  const [address2, setAddress2] = useState("")
  const [city, setCity] = useState("")
  const [postcode, setPostcode] = useState("")
  const [country, setCountry] = useState("")

  useEffect(() => {
    if (
      name !== "" &&
      email.includes("@") &&
      address !== "" &&
      city !== "" &&
      postcode !== "" &&
      country !== ""
    ) {
      setAllFormsFilled(true)
    } else {
      setAllFormsFilled(false)
    }
  }, [name, email, address, city, postcode, country, localPickup])

  const handleLocalPickup = () => {
    // Toggle local pickup
    setLocalPickup(!localPickup)

    if (!localPickup) {
      setAddress("Local Pickup")
      setCity("Local Pickup")
      setPostcode("Local Pickup")
      setCountry("Local Pickup")
    } else {
      setAddress("")
      setCity("")
      setPostcode("")
      setCountry("")
    }
  }

  const handleCheckout = async () => {
    // Hold discount amount
    const discount = state.discount

    // Calculate shipping price
    const shippingPrice = (function (country) {
      switch (country) {
        case "Local Pickup":
          return 0
        case "Netherlands":
          return 10
        default:
          return 15
      }
    })(country)

    // Reduce cart product total
    const productTotalMinusShipping = state.cart.reduce(
      (prev, curr) => {
        const isOnSale = curr.product.productSale > 0

        if (isOnSale) {
          return {
            value:
              prev.value +
              getSalePrice(curr.product.productPrice, curr.product.productSale),
          }
        }

        return {
          value: prev.value + curr.product.productPrice,
        }
      },
      { value: 0 }
    )

    // Calculate discount price without shipping
    const productTotalWithDiscount = getDiscountPrice(
      productTotalMinusShipping.value,
      discount
    )

    // Add shipping to product total with discount applied
    // If under 200 euros, shipping is free
    let grandTotal

    if (productTotalMinusShipping >= 200) {
      grandTotal = { value: productTotalWithDiscount }
    } else {
      grandTotal = { value: productTotalWithDiscount + shippingPrice }
    }

    // Add items to metadata
    const items = state.cart
      .map(item => {
        return item.product.productName
      })
      .join(", ")

    // Add in contact + shipping information
    const data = {
      name: name,
      email: email,
      telephone: telephone,
      address: address,
      address2: address2,
      city: city,
      postcode: postcode,
      country: country,
      discount: discount,
      shippingPrice: shippingPrice,
      productTotal: productTotalMinusShipping.value - shippingPrice,
      discountProductTotal: productTotalWithDiscount,
      items: items,
      ...grandTotal,
    }

    setProcessing(true)
    await fetch(constants.mollieCheckoutUrl, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => (window.location.href = data))
      .then(() => {
        setProcessing(false)
      })
      .catch(err => console.error(err))
  }

  return (
    <Layout>
      <SEO title="Checkout" />
      {width > 1278 ? <DesktopHeader /> : null}
      <motion.div id={"checkout-container"}>
        <motion.div id={"contact-info-container"}>
          <motion.h3 className={"checkout-header"}>CONTACT INFO</motion.h3>
          <Input
            onChange={e => setName(e.target.value)}
            animate={name === "" ? "error" : "initial"}
            placeholder={"* FULL NAME"}
          />
          <Input
            onChange={e => setEmail(e.target.value)}
            animate={email.includes("@") ? "initial" : "error"}
            placeholder={"* EMAIL"}
          />
          <Input
            onChange={e => setTelephone(e.target.value)}
            placeholder={"TELEPHONE"}
          />
        </motion.div>
        <motion.div id={"shipping-info-container"}>
          <motion.h3 className={"checkout-header"}>SHIPPING INFO</motion.h3>
          <Checkbox
            animate={localPickup ? "checked" : "initial"}
            onToggle={handleLocalPickup}
            label={"LOCAL PICKUP (€0 SHIPPING)"}
          />
          {localPickup ? (
            <motion.div id={"local-pickup-container"}>
              <motion.p className={"paragraph uppercase"}>
                LOCAL PICKUP IS FREE. VISIT US AT{" "}
                <a
                  href={"https://g.page/im-material-amsterdam?share"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Haarlemmerdijk 171, 1013 KH AMSTERDAM
                </a>{" "}
                TO PICK UP YOUR ORDER.
              </motion.p>
              <br />
              <motion.p className={"paragraph uppercase"}>
                EMAIL{" "}
                <a href={"mailto:immaterialcorp@gmail.com"}>
                  IMMATERIALCORP@GMAIL.COM
                </a>{" "}
                IF YOU HAVE ANY QUESTIONS.
              </motion.p>
            </motion.div>
          ) : (
            <>
              <Input
                onChange={e => setAddress(e.target.value)}
                animate={address === "" ? "error" : "initial"}
                placeholder={"* ADDRESS"}
              />
              <Input
                onChange={e => setAddress2(e.target.value)}
                placeholder={"ADDRESS 2"}
              />
              <Input
                onChange={e => setCity(e.target.value)}
                animate={city === "" ? "error" : "initial"}
                placeholder={"* CITY"}
              />
              <Input
                onChange={e => setPostcode(e.target.value)}
                animate={postcode === "" ? "error" : "initial"}
                placeholder={"* POSTCODE"}
              />
              <CountrySelector
                onCountryChange={country => setCountry(country)}
              />
            </>
          )}
        </motion.div>
        <motion.div id={"checkout-button-container"}>
          <Button
            text={"BACK TO CART"}
            to={"/cart"}
            className={"checkout-cart-button"}
          />
          <Button
            text={processing ? "PROCESSING" : "PROCEED TO PAYMENT"}
            disabled={!allFormsFilled || processing}
            onClick={handleCheckout}
            animate={!allFormsFilled ? "disabled" : "initial"}
            className={"checkout-payment-button"}
          />
        </motion.div>
      </motion.div>
    </Layout>
  )
}

export default CheckoutPage
